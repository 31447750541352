.Footer {
    background-color: white;
    padding: 1.25rem;
    /* 20px converted to rem */
    text-align: center;
    width: 100%;
    height: 2.5rem;
    /* 40px converted to rem */
    display: none;
    /* Hide footer by default */
    z-index: 1000;
    /* Ensure it is above other elements */
    border-radius: 0.625rem;
    /* 10px converted to rem */
    position: fixed;
    bottom: 0;
}

.Footer-nav {
    display: flex;
    justify-content: center;
    align-items: center;
}

nav ul {
    list-style: none;
    display: flex;
    justify-content: space-between;
    /* Spread links across the footer */
    width: 100%;
    /* Ensure the nav takes full width */
    padding: 0;
    margin: 0;
}

nav ul li {
    display: inline;
}

nav ul li a {
    text-decoration: none;
    color: black;
    display: flex;
}

.nav-icon {
    width: 1.25rem;
    /* 20px converted to rem */
    height: 1.25rem;
    /* 20px converted to rem */
    margin-right: 0.5rem;
    /* 8px converted to rem */
}

.nav-label {
    display: inline;
    margin-bottom: 1rem;
}

/* Media query for mobile screens */
@media (max-width: 37.5em) {

    /* 600px converted to em */
    .Footer {
        display: block;
        /* Show footer on mobile */
        height: 1.5rem;
        justify-content: space-between;
        width: 90%;
        /* 40px converted to rem */
        /* Adjust this value based on the height of your footer */
    }

    .nav-label {
        display: none;
        /* Hide labels on mobile */
    }

    nav ul {
        width: 70%;
        /* Spread links across the footer */
    }

    .nav-icon {
        width: 2rem;
        /* 20px converted to rem */
        height: 2rem;
        /* 20px converted to rem */
        margin-right: 0.5rem;
        /* 8px converted to rem */
    }

}